html {
    font-size: 62.5%;
}

root,
html,
body,
#root {
    height: 100%;
}

@keyframes animatedBackground {
    0% { background-image: url('../assets/sing-bali-1-sm.png'); }
    20% { background-image: url('../assets/sing-bali-2-sm.png'); }
    40% { background-image: url('../assets/sing-bali-3-sm.png'); }
    60% { background-image: url('../assets/no-cruise-1-sm.png'); }
    80% { background-image: url('../assets/no-cruise-2-sm.png'); }
    100% { background-image: url('../assets/no-cruise-3-sm.png'); }
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    font-size: 1.2rem;
    color: #fff;
    overflow-y: scroll;

    /* NOTE: This section is for replicating Altrom site slanted background */
    background: linear-gradient(127deg, #003360 0%, #003360 45%, #E6E6E6 0%, #E6E6E6 70%, #FFFFFF 0);
    height: 100vh;
    width: 100%;
    z-index: -1;
}

.text-center {
    text-align: center;
}

.header {
    background: #fff;
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw - 4rem);
    height: 80px;
    display: grid;
}

.program-title {
    display: block;
    color: #BF3C00;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    padding-top: 0.5rem;
}

.lang-toggle {
    cursor: pointer;
    float: right;
}

.split-grid {
    display: grid;
    grid-gap: 2rem;
}

.split-grid * {
    display: block;
}

.container {
    height: auto;
    max-width: 90%;
    padding: 4rem 2rem;
    margin: 0 auto;
    display: grid;
}

.logo {
    background: url('../assets/altrom-logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
    width: 100%;
    height: 60px;
    max-width: 690px;
    cursor: pointer;
}

/* Home state */
.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.trip-images { 
    animation: animatedBackground 30s linear infinite; 
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 200px;
}

.nav {
    padding-top: 100px;
}

.nav-link {
    text-align: center;
    margin: 0;
}

.resources-link {
    cursor: pointer;
    display: inline-block;
    font-size: 1.5em;
    padding: 0 4rem 2rem;
    text-transform: uppercase;
}

.resources-link:hover,
.resources-link:active { 
    color: #BF3C00;
}

/* Resources state */
.resources-list {
    padding: 0;
}

.resources-list li {
    list-style: none;
}

.resources-list li a {
    background-color: #444;
    color: rgba(255, 255, 255, 0.9);
    display: block;
    text-decoration: none;
    padding: 15px 10px 15px 40px;
    margin: 2px 0 18px;
    font-size: 10pt;
    -moz-box-shadow: 0 1pt 4pt #424242;
    -webkit-box-shadow: 0 1pt 4pt #424242;
    box-shadow: 0 1pt 4pt #424242;
    border-radius: 2pt;
    -moz-transition-duration: .6s;
    -webkit-transition-duration: .6s;
    transition-duration: .6s;
}

.resources-list li a:hover {
    background-color: #003360;
}

.resources-list li a.pdf {
    background-image: url('../assets/pdf-icon.png');
    background-position: center left;
    background-repeat: no-repeat;
    text-align: left;
}

.footer-more-details {
    color: #BF3C00;
    font-size: 1.5rem;
    padding-top: 2rem;
}

@media screen and (min-width: 640px) {
    .header {
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
        padding-left: 4rem;
        width: calc(100vw - 6rem);
    }

    .trip-images {
        min-height: 300px;
    }
}

@media screen and (min-width: 768px) {
    .program-title {
        padding-top: 2.5rem;
        text-align: left;
        padding-left: 6rem;
    }
}

@media screen and (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }

    .header {
        width: calc(100vw - 8rem);
        grid-gap: 0;
    }

    .header,
    .logo {
        height: 80px;
    }

    .program-title {
        font-size: 3rem;
    }

    .nav {
        padding-top: 100px;
    }

    .trip-images {
        min-height: 400px;
    }
}

@media screen and (min-width: 1200px) {
    .logo {
        margin-left: 400px;
    }

    .lang-toggle {
        padding-right: 8rem;
    }
}